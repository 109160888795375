<template>
  <div class="new" id="new">
    <content1 id="explore" />
    <content2 id="instaQR" />
    <content3 id="ciinsta" />
    <content4 id="ftinsta" />
    <content5 id="qrinsta" />
    <!-- <content6 id="wqr" /> -->
  </div>
</template>

<script>
export default {
  name: 'new',
  components: {
    'content1': () => import('./Content1.vue'),
    'content2': () => import('./Content2.vue'),
    'content3': () => import('./Content3.vue'),
    'content4': () => import('./Content4.vue'),
    'content5': () => import('./Content5.vue')
  },

  mounted () {
    var VueScrollTo = require('vue-scrollto');
    var element = location.hash

    if (location.hash) {
      this.$nextTick(() => {
        setTimeout(function() {
          VueScrollTo.scrollTo(element)
        }, 100)
      })
    }
  }
}
</script>

<style scoped lang='scss'></style>
